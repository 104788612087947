import React, { useEffect, useState } from 'react';
import './App.css';

function Header({ darkMode, toggleDarkMode }) {
  const headerStyle = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: darkMode ? '#333333' : '#ffffff',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    padding: '20px',
    color: darkMode ? '#ffffff' : '#000000',
  };

  const logoStyle = {
    maxHeight: '50px',
    margin: 0,
  };

  const textStyle = {
    fontSize: '24px',
    fontWeight: 'bold',
    margin: '0 0 0 10px',
    textDecoration: 'none',
    color: darkMode ? '#ffffff' : '#000000',
  };

  const iconStyle = {
    cursor: 'pointer',
    marginLeft: 'auto',
  };

  return (
    <header style={{ ...headerStyle, ...iconStyle }}>
      <div>
        <a href="/">
          <img src="logo800.png" alt="Company Logo" style={logoStyle} />
        </a>
      </div>

      <div style={textStyle}>
        <a href="/" style={{ textDecoration: 'none', color: textStyle.color }}>
          CollegeAdmits
        </a>
      </div>

      <div style={iconStyle} onClick={toggleDarkMode}>
        {darkMode ? '☀️' : '🌙'}
      </div>
    </header>
  );
}

function Footer({ darkMode, toggleDarkMode }) {
  const footerStyle = {
    backgroundColor: darkMode ? '#333333' : '#004aad',
    padding: '30px',
    textAlign: 'center',
    fontSize: '16px',
    position: 'relative',
    bottom: '0',
  };

  const linkStyle = {
    color: '#fff',
    textDecoration: 'none',
    marginLeft: '10px',
    marginRight: '10px',
  };

  const copyrightStyle = {
    marginTop: '20px',
    color: '#fff',
  };

  return (
    <div style={footerStyle}>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <a href="/terms" style={linkStyle}>Terms of Use</a>
        <span style={{ display: 'inline-block', marginLeft: '10px', marginRight: '10px', color: '#fff' }}>|</span>
        <a href="/privacy" style={linkStyle}>Privacy Policy</a>
      </div>
      <div style={copyrightStyle}>
        © 2025 CollegeAdmits. All Rights Reserved.
      </div>
    </div>
  );
}

function Extracurricular() {
    const OPENROUTER_API_KEY = "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"
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [loading, setLoading] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);

  const onChange = (e) => {
    setQuestion(e.target.value);
  };

  const onClick = async () => {
    if (!question) {
      alert("Please enter a college.");
      return;
    }

    setLoading(true);
    const response = await fetch("https://openrouter.ai/api/v1/chat/completions", {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${OPENROUTER_API_KEY}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        "model": "perplexity/llama-3.1-sonar-small-128k-online",
        "messages": [
          { "role": "system", "content": "You are a college research tool. Generate information about college, acceptance rate, average gpa, average test scores, location, popular majors, based on the user input " },
          { "role": "user", "content": question },
        ],
      })
    });

    const data = await response.json();
    const aiResponse = data.choices[0].message.content;
    setAnswer(aiResponse);
    setLoading(false);
  };

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  return (
    <div className={`page-container ${isDarkMode ? 'dark-mode' : ''}`}>
      <div className="content-wrap">
        <Header darkMode={isDarkMode} toggleDarkMode={toggleDarkMode} />
        <h1>College Research Tool</h1>
        <p>Enter a college</p>
        <textarea
          onChange={onChange}
          value={question}
          rows={4}
          cols={50}
          placeholder="Harvard"
          maxLength={550}
          required
        />
        <button onClick={onClick}>Submit</button>
        <div className="container">
          {loading ? (
            <p className="answer">CollegeAdmits is thinking ... </p>
          ) : (
            <p className="answer">{answer}</p>
          )}
        </div>
        <div className="terms-spacer"></div>
      </div>
      <Footer darkMode={isDarkMode} toggleDarkMode={toggleDarkMode} />
    </div>
  );
}

export default Extracurricular;
