import React, { useEffect, useState } from 'react';
import { Configuration, OpenAIApi } from 'openai';
import './App.css';


function Header({ darkMode, toggleDarkMode }) {
  const headerStyle = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: darkMode ? '#333333' : '#ffffff',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    padding: '20px',
    color: darkMode ? '#ffffff' : '#000000',
  };

  const logoStyle = {
    maxHeight: '50px',
    margin: 0,
  };

  const textStyle = {
    fontSize: '24px',
    fontWeight: 'bold',
    margin: '0 0 0 10px',
    textDecoration: 'none',
    color: darkMode ? '#ffffff' : '#000000',
  };

  const iconStyle = {
    cursor: 'pointer', // Make the icon clickable
    marginLeft: 'auto',
  };

  

  return (
    <header style={{ ...headerStyle, ...iconStyle }} >
      <div>
        <a href="/">
          <img src="logo800.png" alt="Company Logo" style={logoStyle} />
        </a>
      </div>

      <div style={textStyle}>
        <a href="/" style={{ textDecoration: 'none', color: textStyle.color }}>
          CollegeAdmits
        </a>
      </div>

      <div style={iconStyle}onClick={toggleDarkMode}>
        {darkMode ? '☀️' : '🌙'}
      </div>
    </header>
  );
}




function Footer({ darkMode, toggleDarkMode }) {
    const footerStyle = {
      backgroundColor: darkMode ? '#333333' : '#004aad',
      padding: '30px',
      textAlign: 'center',
      fontSize: '16px',
      position: 'relative',
      bottom: '0',
      
    };
  
    const linkStyle = {
      color: '#fff',
      textDecoration: 'none',
      marginLeft: '10px',
      marginRight: '10px',
    };
  
    const copyrightStyle = {
      marginTop: '20px',
      color: '#fff',
    }
  
    return (
      <div style={footerStyle}>
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <a href="/terms" style={linkStyle}>
            Terms of Use
          </a>
  
          <span style={{ display: 'inline-block', marginLeft: '10px', marginRight: '10px', color: '#fff'}}>
            |
          </span>
  
          <a href="/privacy" style={linkStyle}>
            Privacy Policy
          </a>
        </div>
  
        <div style={copyrightStyle}>
          © 2025 CollegeAdmits. All Rights Reserved.
        </div>
      </div>
    );
  }
  


  function Collegelist() {
    const OPENROUTER_API_KEY = "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"
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [loading, setLoading] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);

  const onChange = (e) => {
    setQuestion(e.target.value);
  };

  const onClick = async () => {
    if (!question) {
      alert("Please enter a college major, location, GPA, test scores.");
      return;
    }

    setLoading(true);
    const response = await fetch("https://openrouter.ai/api/v1/chat/completions", {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${OPENROUTER_API_KEY}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        "model": "meta-llama/llama-3-8b-instruct:free",
        "messages": [
          { "role": "system", "content": "I want you to generate a list of colleges, include 3 safety, 3 target, and 3 reach colleges based on the user input. Include a short description of each college, their acceptance rate" },
          { "role": "user", "content": question },
        ],
      })
    });

    const data = await response.json();
    const aiResponse = data.choices[0].message.content;
    setAnswer(aiResponse);
    setLoading(false);
  };

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  return (
    <div className={`page-container ${isDarkMode ? 'dark-mode' : ''}`}>
      <div className="content-wrap">
        <Header darkMode={isDarkMode} toggleDarkMode={toggleDarkMode} />
        <h1>College List Generator</h1>
        <p>Enter a college major, location, GPA, test scores</p>
        <textarea
          onChange={onChange}
          value={question}
          rows={4}
          cols={50}
          placeholder="Mechanical engineering major, 3.8 GPA"
          maxLength={600}
          required
        />
        <button onClick={onClick}>Submit</button>
        <div className="container">
          {loading ? (
            <p className="answer">CollegeAdmits is thinking ... </p>
          ) : (
            <p className="answer">{answer}</p>
          )}
        </div>
        <div className="terms-spacer"></div>
      </div>
      <Footer darkMode={isDarkMode} toggleDarkMode={toggleDarkMode} />
    </div>
  );
}

export default Collegelist;
